import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["templateId", "content", "abcTemplates", "popTemplates", "filterLink"]
  static values = {
    filter: { type: String, default: "abc_asc" },
    selectedTemplateId: { type: Number, default: 0 },
    selectedTemplateIndex: { type: Number, default: 0 },
    ascTemplates: Array,
    popTemplates: Array
  }

  connect() {
    this.ascTemplatesValue = JSON.parse(this.element.dataset.ascArticleTemplates)
    this.popTemplatesValue = JSON.parse(this.element.dataset.popArticleTemplates)

    if (this.ascTemplatesValue[0]) {
      this.selectedTemplateIdValue = this.ascTemplatesValue[0].id
      this.updateContent()
    }
  }

  changeFilter(event) {
    event.preventDefault()
    const newFilter = event.currentTarget.dataset.filter
    this.filterValue = newFilter
    this.selectedTemplateIndexValue = 0

    // Update filter link states
    this.filterLinkTargets.forEach(link => {
      link.classList.toggle('selected', link.dataset.filter === newFilter)
    })

    if (this.filterValue === "abc_asc") {
      this.selectedTemplateIdValue = this.ascTemplatesValue[0].id
      this.abcTemplatesTarget.classList.remove("hidden")
      this.popTemplatesTarget.classList.add("hidden")
    } else {
      this.selectedTemplateIdValue = this.popTemplatesValue[0].id
      this.abcTemplatesTarget.classList.add("hidden")
      this.popTemplatesTarget.classList.remove("hidden")
    }

    // Reset selected state on templates
    const currentTemplates = this.filterValue === "abc_asc" ?
      this.abcTemplatesTarget.querySelectorAll('.template_chooser__template') :
      this.popTemplatesTarget.querySelectorAll('.template_chooser__template')

    currentTemplates.forEach((template, index) => {
      template.classList.toggle('selected', index === 0)
    })

    this.updateContent()
  }

  chooseTemplate(event) {
    event.preventDefault()
    const index = parseInt(event.currentTarget.dataset.index)
    const id = parseInt(event.currentTarget.dataset.templateId)

    this.selectedTemplateIdValue = id
    this.selectedTemplateIndexValue = index

    // Update selected class for the current filter's templates
    const container = event.currentTarget.closest('[data-template-chooser-target]')
    const templates = container.querySelectorAll('.template_chooser__template')
    templates.forEach(t => t.classList.remove('selected'))
    event.currentTarget.classList.add('selected')

    this.updateContent()
  }

  updateContent() {
    this.templateIdTarget.value = this.selectedTemplateIdValue
    const templates = this.filterValue === "abc_asc" ? this.ascTemplatesValue : this.popTemplatesValue
    const template = templates[this.selectedTemplateIndexValue]

    if (template) {
      this.contentTarget.innerHTML = `
        <h1 class="template_chooser__content__title">${template.name}</h1>
        <div>${template.content}</div>
      `
    }
  }
}
