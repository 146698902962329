import { Controller } from "@hotwired/stimulus";
import CryptoJS from "crypto-js";
import flash from "../lib/flashes.js"

export default class extends Controller {
  static targets = ["visibleNote", "encryptedNote", "formSection", "successSection", "shareUrl"]

  connect() {
    // Generate encryption key when controller connects
    this.encryptionKey = this.generateKey();
  }

  encryptNote() {
    const plaintext = this.visibleNoteTarget.value;
    if (plaintext) {
      // Encrypt the note content
      const encrypted = CryptoJS.AES.encrypt(plaintext, this.encryptionKey).toString();

      // Store encrypted value in hidden field
      this.encryptedNoteTarget.value = encrypted;

      // Append encryption key to form action URL
      const form = this.element;
      const originalAction = form.action;
      form.action = `${originalAction}?key=${this.encryptionKey}`;
    }
  }

  // Helper method to generate random encryption key
  generateKey(length = 40) {
    const array = new Uint8Array(length / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, this.dec2hex).join('');
  }

  // Helper method for hex conversion
  dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }

  async onSubmit(event) {
    event.preventDefault()

    // First encrypt the note (using existing encryptNote method)
    this.encryptNote()

    // Get form data and submit
    const form = event.target
    const formData = new FormData(form)

    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: formData,
        headers: {
          "Accept": "application/json"
        }
      })

      const data = await response.json()

      if (response.ok) {
        // Show success section and hide form
        this.formSectionTarget.style.display = "none"
        this.successSectionTarget.style.display = "flex"

        // Get token from the parsed JSON response data
        const shareUrl = `${data.url}?key=${this.encryptionKey}`
        this.shareUrlTarget.value = shareUrl
      }
    } catch (error) {
      console.error("Error creating secure note:", error)
    }
  }

  copyLink() {
    this.shareUrlTarget.select()
    document.execCommand('copy')
    flash("Copied", "success")
  }

  reset() {
    // Reset form fields
    const form = this.formSectionTarget.querySelector('form')
    form.reset()

    // Clear the encrypted note field
    this.encryptedNoteTarget.value = ''

    // Clear the visible note field
    this.visibleNoteTarget.value = ''

    // Clear the share URL
    this.shareUrlTarget.value = ''

    // Reset display states
    this.formSectionTarget.style.display = "block"
    this.successSectionTarget.style.display = "none"

    // Generate new encryption key
    this.encryptionKey = this.generateKey()

    // Set default value for expiration date select
    const expirationSelect = form.querySelector('select[name="secure_note[expiration_date]"]')
    if (expirationSelect && expirationSelect.options.length > 0) {
      expirationSelect.selectedIndex = 0  // Select first option
    }

    // Re-enable the submit button
    const submitButton = form.querySelector('input[type="submit"]')
    if (submitButton) {
      submitButton.disabled = false
    }
  }
}
