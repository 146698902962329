import { Notyf } from 'notyf';

export default function flash(message, flashType) {
  var notyf = new Notyf();
  if (flashType == "success") {
    notyf.success({message: message, duration: 5000, dismissible: true, background: "#0E700E"});
  } else {
    notyf.error({message: message, duration: 5000, dismissible: true, background: "#B10E1C"});
  }
}
