<template>
  <div class='asset-sidebar__group asset-sidebar__group--photo-manager' @keydown.esc="close">
    <header>
      <h2>
        <widget-toggler v-if='!show_filter_bar' name="Photos">Photos ({{total}})</widget-toggler>
      </h2>
      <a href='#' class='button button--tertiary' v-if='can_create' @click.prevent.stop='paste($event)'><i class="fas fa-paste"></i> Paste</a>
    </header>
    <div :class="{'asset-sidebar__group-content asset-sidebar__group-content--Photos': !show_filter_bar}">
      <div v-show='can_create'>
        <vue-dropzone ref="myVueDropzone" id="dropzone" @vdropzone-success="vsuccess" :options="dropzoneOptions"></vue-dropzone>
      </div>
      <slot></slot>
      <div class="photo-manager__filterbar" v-if="show_filter_bar && (photos.length > 0 || query)">
        <form @submit.prevent.stop>
          <div class='table-scroll__search'>
            <i class="fas fa-search"></i>
            <input @input.prevent.stop="loadPhotos" v-model='query' type="search" placeholder="Search" name="query" />
          </div>
          <div class="toggle-button">
            <a href="#" :class="{'selected': format == 1}" @click.prevent.stop="format = 1"><i class='far fa-grid-2'></i></a>
            <a href="#" :class="{'selected': format == 2}" @click.prevent.stop="format = 2"><i class='far fa-grid'></i></a>
            <a href="#" :class="{'selected': format == 3}" @click.prevent.stop="format = 3"><i class='far fa-list'></i></a>
          </div>
          <label class="photo-manager__filtersort">
            <span>Sort by:</span>
            <select @change.prevent.stop="loadPhotos" v-model="sort">
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
              <option value="name_ascending">Name A→Z</option>
              <option value="name_descending">Name Z→A</option>
            </select>
          </label>
        </form>
      </div>
      <div :class='{"photo-manager": true, "photo-manager--lg": format == 1, "photo-manager--single": format == 3}' v-if='photos.length > 0'>
        <div v-for='(photo, index) in photos' class='photo-manager__img'>
          <a href='#' @click.prevent.stop='open(index)' class='photo-manager__preview'>
            <template v-if='can_create'>
              <a class='photo-manager__featured photo-manager__featured--unpinned' v-if="!photo.pinned" href="#" @click.prevent.stop="togglePinned(photo.id, true)"><i class='far fa-thumbtack'></i></a>
              <a class='photo-manager__featured' v-if="photo.pinned" href="#" @click.prevent.stop="togglePinned(photo.id, false)"><i class='fas fa-thumbtack'></i></a>
            </template>
            <vue-load-image>
              <img slot="image" :src='photo.url' />
              <div slot="error" class='photo-manager__no'><i class='fas fa-image'></i></div>
            </vue-load-image>
          </a>
          <div>
            <span>{{photo.caption || photo.original_filename}}</span>
            <span class="record-name" v-if="format == 3">{{photo.record_name}}</span>
          </div>
        </div>
      </div>
      <div class='table__message' v-if='photos.length == 0 && query'>
        <h3>No results</h3>
        <p>Please adjust your search parameters and try again</p>
      </div>
      <div class="photo-manager__load" v-if='total != photos.length'>
        <div class="button-group">
          <a @click.prevent.stop='loadAllPhotos' class="button button--secondary" href="#">View All</a>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <div class='photo-manager__bg' v-show='active != -1'>
        <div class='photo-manager__modal' v-if='active != -1'>
          <header>
            <div class='photo-manager__name'>
              <i class="fas fa-camera"></i>
              <div>
                <template v-if='is_portal'>
                  <h2 class="photo-manager__photo-name">{{photos[active].caption || photos[active].original_filename}}</h2>
                </template>
                <template v-if='!is_portal'>
                  <div class="photo-manager__name-title">
                    <h2 class="photo-manager__company-name" v-if="photos[active].company_name"><a :href='photos[active].record_company_url'>{{photos[active].company_name}}</a></h2>
                    <h2 class='photo-manager__record-name'><a :href='photos[active].record_url'>{{photos[active].record_name}}</a></h2>
                    <h2 class="photo-manager__photo-name" v-if='!isEdit'>
                      <a href='#' @click.prevent.stop='toggleEdit' class='name__edit' v-if='can_create'>{{ photos[active].caption  || photos[active].original_filename }} <i class='far fa-pencil'></i></i></a>
                      <template v-if='!can_create'>{{photos[active].caption || photos[active].original_filename}}</template>
                    </h2>
                    <div v-if='isEdit' class='name__edit-form'>
                      <form @submit.prevent.stop='saveEdit'>
                        <input type='text' id='newNameInputForCaption' v-model='newName' placeholder="Enter a new name" required="true" v-on:keyup.enter="saveEdit" v-click-outside="saveEdit">
                      </form>
                    </div>
                  </div>
                </template>
                <div>
                  <h4><span>{{photos[active].size }}</span><span>{{photos[active].mime }}</span><span>{{photos[active].created_date }}</span></h4>
                </div>
              </div>
            </div>
            <div class="button-toolbar">
              <a :href='photos[active].original_url' data-tippy-content="Download"><i class='far fa-download'></i></a>
              <a href='#' v-if='!isFullScreen' @click.prevent.stop='openFullScreen' data-tippy-content="Open Full Screen"><i class='far fa-expand-alt'></i></a>
              <a href='#' v-if='isFullScreen' @click.prevent.stop='closeFullScreen' data-tippy-content="Exit Full Screen"><i class='far fa-compress-alt'></i></a>
              <a href='#' @click.prevent.stop='print(photos[active].original_url)' data-tippy-content="Print"><i class='far fa-print'></i></a>
              <a href="#" v-if='can_delete' @click.prevent.stop='deletePhoto(photos[active].id, index)' data-tippy-content="Delete photo"><i class='far fa-trash'></i></a>
              <a href='#' @click.prevent.stop='close' data-tippy-content="Close"><i class='far fa-times'></i></a>
            </div>
          </header>
          <main>
            <vue-load-image>
              <img slot="image" :src='photos[active].original_url' />

              <div slot="error" class='photo-manager__no photo-manager__no--lg'><i class='fas fa-image'></i></div>
            </vue-load-image>
          </main>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport';
import vue2Dropzone from 'vue2-dropzone'
import VueLoadImage from 'vue-load-image'
import WidgetToggler from './WidgetToggler.vue';
import vClickOutside from 'v-click-outside'
import flash from "../lib/flashes.js";

export default {
  props: ["type", "show", "is_portal",
          "url", "base_url", "photoable_id", "photoable_type", "show_filter_bar",
          "can_create", "can_delete", "max_file_size"],
          data: function () {
    return {
      dropzoneOptions: {
          url: this.url,
          parallelUploads: 10,
          createImageThumbnails: false,
          acceptedFiles: ".jpeg,.jpg,.png,.gif",
          maxFilesize: this.max_file_size,
          dictDefaultMessage: "Drop photos here to upload <br><br>100 MB max. Accepts .jpeg, .jpg, .png, .gif",
          thumbnailMethod: "contain",
          thumbnailHeight: 85,
          thumbnailWidth: 85,
          params: {
            photoable_id: this.photoable_id,
            photoable_type: this.photoable_type
          }
      },
      isEdit: false,
      add: false,
      active: -1,
      editIndex: -1,
      newName: "",
      format: this.show_filter_bar ? 1 : 2,
      all_photos: false,
      total: 0,
      photos: [],
      isShown: true,
      isToggled: true,
      isFullScreen: false,
      sort: "newest",
      query: ""
    }
  },
  components: {
    Teleport,
    WidgetToggler,
    vueDropzone: vue2Dropzone,
    'vue-load-image': VueLoadImage
  },
  mounted () {

    var that = this;

    this.loadPhotos();

    document.addEventListener("keyup", this.handleArrows);
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    togglePinned (id, pinned) {
      var that = this;
      that.$api.put(`${that.base_url}/${id}/toggle_pinned`).then(function (response) {
        that.loadPhotos();
        if (pinned) {
          flash('Pinned','success')
        } else {
          flash('Unpinned','success')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    loadAllPhotos () {
      this.all_photos = true;
      this.loadPhotos();
    },
    loadPhotos() {
      var that = this;

      that.$api.get(that.url, {params: {sort: that.sort, return_company_records: (that.show_filter_bar), query: that.query, all_photos: that.all_photos}}).then(function (response) {
        that.photos = response.data.photos;
        that.total = response.data.meta.total;
      }).catch(error => {
        console.log(error)
      })
    },
    toggleEdit () {
      this.isEdit = !this.isEdit;
      this.newName = this.photos[this.active].caption;
      this.$nextTick(() => document.querySelector("#newNameInputForCaption").focus())
    },
    print (source) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(this.imageToPrint(source));
      pwa.document.close();
    },
    imageToPrint (source) {
      return "<html><head><scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                "<img src='" + source + "' /></body></html>";
    },
    openFullScreen () {
      this.isFullScreen = true;
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    closeFullScreen () {
      this.isFullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    },
    paste (event) {

      getClipboardContents()

      var allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];
      var that = this;
      async function getClipboardContents() {
        try {
          const clipboardItems = await navigator.clipboard.read();
          for (const clipboardItem of clipboardItems) {
            for (const type of clipboardItem.types) {
              const blob = await clipboardItem.getType(type);

              if (allowedFileTypes.indexOf(blob.type) > -1) {

                var createdFileName = ""

                if (blob.type == "image/png") {
                  createdFileName = "pastedPhoto.png";
                } else if (blob.type == "image/jpeg") {
                  createdFileName = "pastedPhoto.jpeg";
                } else {
                  createdFileName = "pastedPhoto.gif";
                }


                var fileOfBlob = new File([blob], createdFileName);
                that.$refs.myVueDropzone.addFile(fileOfBlob)
              } else {
                flash('Unsupported photo format','error')
              }
            }
          }
        } catch (err) {
          flash('Your browser does not support this :(','error')
        }
      }
    },
    handleArrows () {
      if (document.querySelector("#newNameInputForCaption") === document.activeElement) {
        return;
      }
      if (event.keyCode == 37 && this.active > 0) {
        this.active--
        this.newCaption = ""
        this.isEdit = false;
      } else if (event.keyCode == 39 && this.active < (this.photos.length - 1)) {
        this.active++
        this.newCaption = ""
        this.isEdit = false;
      }
    },
    vsuccess ( file, response) {
      var that = this;

      that.loadPhotos();
      flash('Photo added','success')
    },
    saveEdit () {
      var that = this;
      if (that.newName == this.photos[this.active].caption) {
        that.isEdit = false
        that.newName = "";
        return
      }
      var id = this.photos[this.active].id
      that.$api.put(`${that.base_url}/${id}`, { caption: that.newName}).then(function (response) {
        that.isEdit = false;
        that.newName = "";
        that.loadPhotos();
        flash('Photo updated','success')
      }).catch(error => {
        console.log(error)
      })
    },
    deletePhoto (id, index) {
      var that = this;

      if(confirm('Are you sure?')) {
        that.$api.delete(`${that.base_url}/${id}`).then(function (response) {
          that.close()
          that.photos.splice(index, 1);
          that.loadPhotos();
          flash('Photo deleted','success')
        }).catch(error => {
          console.log(error)
        })
      }
    },
    cancelEdit () {
      this.newCaption = "";
      this.isEdit = false;
    },
    close () {
      this.active = -1
      this.newCaption = ""
      this.isEdit = false;
      if (this.isFullScreen) {
        this.closeFullScreen();
      }

    },
    open (index) {
      this.active = index;
    }
  }
}
</script>

