import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hideable", "icon", "text" ]
  static values = {
    display: { type: String, default: "block" },
    localStorageName: String,
    hideText: String,
    showText: String
  }
  static classes = [ "hide", "show" ]

  connect() {
    if (this.localStorageNameValue) {
      const isHidden = localStorage.getItem(this.localStorageNameValue) === 'true'
      if (isHidden) {
        this.hideableTargets.forEach(el => this.hide(el))
      } else {
        this.hideableTargets.forEach(el => this.show(el))
      }
    }
  }

  show(el) {
    el.style.display = this.displayValue;
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove(this.showClass);
      this.iconTarget.classList.add(this.hideClass);
    }
  }

  hide(el) {
    el.style.display = "none";
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove(this.hideClass);
      this.iconTarget.classList.add(this.showClass);
    }
  }

  toggleTargets() {
    const isCurrentlyHidden = this.hideableTargets[0].style.display === "none";

    this.hideableTargets.forEach((el) => {
      if (isCurrentlyHidden) {
        this.show(el)
      } else {
        this.hide(el)
      }
    });

    this.updateText(!isCurrentlyHidden);

    if (this.hasLocalStorageNameValue) {
      localStorage.setItem(this.localStorageNameValue, !isCurrentlyHidden)
    }
  }

  updateText(isHidden) {
    if (this.hasHideTextValue && this.hasShowTextValue && this.hasTextTarget) {
      this.textTarget.textContent = isHidden ? this.showTextValue : this.hideTextValue;
    }
  }
}
