import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["container"]
  static values = {
    fetchUrl: String,
    updateUrl: String
  }

  connect() {
    this.loadFields()
    this.initializeSortable()
  }

  initializeSortable() {
    Sortable.create(this.containerTarget, {
      animation: 150,
      onEnd: this.handleSortEnd.bind(this)
    })
  }

  async loadFields() {
    try {
      const response = await fetch(this.fetchUrlValue)
      const fields = await response.json()
      this.currentFields = fields
      this.renderFields(fields)
    } catch (error) {
      console.error("Error loading fields:", error)
    }
  }

  renderFields(fields) {
    this.containerTarget.innerHTML = fields.map((field, index) => `
      <div class="dashboard_item_sorter__item ${field.hidden ? 'dashboard_item_sorter__item--muted' : ''}"
           data-field-index="${index}">
        <article>
          <h1><i class='fas fa-bars icon'></i>${field.name}</h1>
          <p>${field.summary}</p>
        </article>
        <a href="#"
           data-action="click->dashboard-item-sorter#toggleHidden"
           data-field-index="${index}"
           class="button button--secondary">
          ${field.hidden ?
            '<i class="fas fa-eye"></i>Show' :
            '<i class="fas fa-eye-slash"></i>Hide'
          }
        </a>
      </div>
    `).join('')
  }

  async toggleHidden(event) {
    event.preventDefault()
    const index = parseInt(event.currentTarget.dataset.fieldIndex)
    const fields = [...this.currentFields]
    fields[index].hidden = !fields[index].hidden

    await this.updateFieldsWithoutReload(fields)
    this.renderFields(fields)
  }

  async handleSortEnd(event) {
    const newFields = Array.from(this.containerTarget.children).map((element, index) => {
      const fieldIndex = parseInt(element.dataset.fieldIndex)
      return {
        ...this.currentFields[fieldIndex],
        position: index + 1
      }
    })

    await this.updateFieldsWithoutReload(newFields)
    this.renderFields(newFields)
  }

  async updateFieldsWithoutReload(fields) {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      const response = await fetch(this.updateUrlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/json'
        },
        body: JSON.stringify({ dashboard_fields: fields })
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      this.currentFields = fields
    } catch (error) {
      console.error("Error updating fields:", error)
      alert("Something went wrong...")
      await this.loadFields()
    }
  }
}
