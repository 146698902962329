import consumer from "./consumer"

consumer.subscriptions.create("InstallerBuilderJobStatusChannel", {
  connected() {
    // console.log("Connected to JobStatusChannel");
  },

  disconnected() {
    // console.log("Disconnected from JobStatusChannel");
  },

  received(data) {
    // console.log("Data recieved");
  },
});