import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hostFields", "guestFields"]

  connect() {
    this.toggleFields()
  }

  toggleFields() {
    const isHost = this.element.querySelector('input[name="bridge[side]"]:checked').value === "host"

    this.hostFieldsTarget.style.display = isHost ? "block" : "none"
    this.guestFieldsTarget.style.display = isHost ? "none" : "block"
  }
}
