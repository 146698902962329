import CheckboxSelectAll from 'stimulus-checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ["actions", "count", "action", "idInput", "container", "previewLink", "table"]

  connect() {
    super.connect();

    (function () {
      var thElm;
      var startOffset;
      var startWidths = {};
      var isResizing = false;

      var columnWidth = {};
      if (localStorage.getItem('columnWidth')) {
          try {
              columnWidth = JSON.parse(localStorage.getItem('columnWidth'));
              Object.entries(columnWidth).forEach(([key, value]) => {
                  if (!value.endsWith('px')) {
                      columnWidth[key] = value + 'px';
                  }
              });
          } catch (e) {
              console.error('Error loading stored column widths:', e);
              columnWidth = {};
          }
      }

      Array.prototype.forEach.call(
          document.querySelectorAll("table thead th"),
          function (th) {
              const table = th.closest('table');
              const columnIndex = th.cellIndex;

              // Skip resizable columns if table has fixed-column class
              if (table.closest('.table-scroll--non-resizable')) {
                  const cells = table.querySelectorAll(`tr td:nth-child(${columnIndex + 1}), tr th:nth-child(${columnIndex + 1})`);
                  cells.forEach(cell => {
                      cell.style.width = '100%';
                  });
                  return;
              }

              const cells = table.querySelectorAll(`tr td:nth-child(${columnIndex + 1}), tr th:nth-child(${columnIndex + 1})`);
              cells.forEach(cell => {
                  cell.style.overflow = 'hidden';
                  cell.style.textOverflow = 'ellipsis';
                  cell.style.whiteSpace = 'nowrap';
              });

              if (columnWidth && columnWidth[th.dataset.columnResizable]) {
                  cells.forEach(cell => {
                      cell.style.width = columnWidth[th.dataset.columnResizable];
                      cell.style.minWidth = columnWidth[th.dataset.columnResizable];
                      cell.style.maxWidth = columnWidth[th.dataset.columnResizable];
                  });
              } else if (th.dataset.columnResizable) {
                  const initialWidth = th.offsetWidth + 'px';
                  cells.forEach(cell => {
                      cell.style.minWidth = initialWidth;
                      cell.style.width = initialWidth;
                      cell.style.maxWidth = initialWidth;
                  });
                  columnWidth[th.dataset.columnResizable] = initialWidth;
              }

              var grip = document.createElement('div');
              grip.classList.add('table__grip');
              grip.addEventListener('mousedown', function (e) {
                  thElm = th;
                  startOffset = th.offsetWidth - e.pageX;
                  isResizing = true;

                  e.preventDefault();
                  document.body.style.cursor = 'col-resize';

                  document.addEventListener('mousemove', onMouseMove);
                  document.addEventListener('mouseup', onMouseUp);
              });

              th.appendChild(grip);
          });

      function onMouseMove(e) {
          if (thElm && isResizing) {
              e.preventDefault();

              const width = Math.max(40, startOffset + e.pageX);
              const newWidth = width + 'px';
              const columnIndex = thElm.cellIndex;
              const table = thElm.closest('table');

              if (!thElm.resizableCells) {
                  thElm.resizableCells = table.querySelectorAll(
                      `tr td:nth-child(${columnIndex + 1}), tr th:nth-child(${columnIndex + 1})`
                  );
              }

              thElm.resizableCells.forEach(cell => {
                  cell.style.width = newWidth;
                  cell.style.minWidth = newWidth;
                  cell.style.maxWidth = newWidth;
              });

              columnWidth[thElm.dataset.columnResizable] = newWidth;
          }
      }

      function onMouseUp() {
          if (thElm) {
              try {
                  localStorage.setItem('columnWidth', JSON.stringify(columnWidth));
              } catch (e) {
                  console.error('Error saving column widths:', e);
              }

              document.body.style.cursor = '';

              thElm = undefined;
              isResizing = false;

              document.removeEventListener('mousemove', onMouseMove);
              document.removeEventListener('mouseup', onMouseUp);
          }
      }
  })();


    this.values = []
    this.element[this.identifier] = this

    if (this.containerTarget.classList.contains("table-scroll--fixed-column")) {
      if (this.containerTarget.getBoundingClientRect().bottom > window.innerHeight) {
        this.containerTarget.style.height = (window.innerHeight - this.containerTarget.getBoundingClientRect().top) + "px"
      }
    }

    if (this.previewLinkTargets.length > 0) {
      this.previewLinkTargets.forEach(function(elem) {
        const cell = elem.closest("td") || elem.closest("th");
        if (cell) {
          cell.addEventListener("click", (e) => {
            if (e.srcElement.tagName != "A" && e.srcElement.tagName != "INPUT") {
              elem.click()
            }
          })
        }
      });
    }

    if (this.containerTarget.classList.contains("table-scroll--fixed-column")) {
      this.containerTarget.style.overflowY = "scroll"
    }
  }

  check () {
    const actionsTarget = this.actionsTarget
    const countTarget = this.countTarget
    let params = new URLSearchParams();

    countTarget.innerHTML = this.checked.length + " selected";

    if (this.checked.length > 0) {
      actionsTarget.style.display = "flex"

    } else {
      actionsTarget.style.display = "none"
    }

    this.checked.forEach((element) => {
      element.closest("tr").classList.add("selected")
    });

    this.unchecked.forEach((element) => {
     element.closest("tr").classList.remove("selected")
    });
  }

  action (e) {
    e.preventDefault()
    var that = this;

    this.runAction(e)
  }

  deleteAction(e) {
    e.preventDefault()
    var confirmation = confirm("Are you sure you want to permanently delete " + this.checked.length + " items?");

    if (confirmation) {
      this.runAction(e)
    }
  }

  runAction (e) {
    var checked_values = this.checked.map(function (el) {
      return el.value
    })

    this.idInputTargets.forEach((el) => {
      el.value = JSON.stringify( checked_values )
    })

    e.target.closest('form').submit()
  }

}
