import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import flash from "../lib/flashes.js"

export default class extends Controller {
  static targets = ["container", "handle", "item"]

  connect() {
    if (this.hasContainerTarget) {
      this.initializeSortable()
    }
  }

  initializeSortable() {
    this.sortable = new Sortable(this.containerTarget, {
      handle: ".fa-grip-vertical",
      animation: 150,
      onEnd: this.updateOrder.bind(this)
    })
  }

  updateOrder(event) {
    const items = this.itemTargets
    const positions = items.map((item, index) => ({
      id: item.dataset.id,
      position: index + 1
    }))

    fetch(this.element.dataset.updateUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ positions: positions })
    }).then(response => {
      flash("Positions updated","success")
    }).catch(error => {
      flash("Something went wrong","error")
    })
  }
}
