import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fullAddressInput", "addressInput", "maskSelect", "usableIps", "mask", "maskBits", "wildcard"]
  static values = {
    options: Array
  }

  connect() {
    this.optionsValue = [
      {
        "address": "/1",
        "subnet_mask": "128.0.0.0",
        "addresses": 2147483646,
        "wildcard": "127.255.255.255"
      },
      {
        "address": "/2",
        "subnet_mask": "192.0.0.0",
        "addresses": 1073741822,
        "wildcard": "63.255.255.255"
      },
      {
        "address": "/3",
        "subnet_mask": "224.0.0.0",
        "addresses": 536870910,
        "wildcard": "31.255.255.255"
      },
      {
        "address": "/4",
        "subnet_mask": "240.0.0.0",
        "addresses": 268435454,
        "wildcard": "15.255.255.255"
      },
      {
        "address": "/5",
        "subnet_mask": "248.0.0.0",
        "addresses": 134217726,
        "wildcard": "7.255.255.255"
      },
      {
        "address": "/6",
        "subnet_mask": "252.0.0.0",
        "addresses": 67108862,
        "wildcard": "3.255.255.255"
      },
      {
        "address": "/7",
        "subnet_mask": "254.0.0.0",
        "addresses": 33554430,
        "wildcard": "1.255.255.255"
      },
      {
        "address": "/8",
        "subnet_mask": "255.0.0.0",
        "addresses": 16777214,
        "wildcard": "0.255.255.255"
      },
      {
        "address": "/9",
        "subnet_mask": "255.128.0.0",
        "addresses": 8388606,
        "wildcard": "0.127.255.255"
      },
      {
        "address": "/10",
        "subnet_mask": "255.192.0.0",
        "addresses": 4194302,
        "wildcard": "0.63.255.255"
      },
      {
        "address": "/11",
        "subnet_mask": "255.224.0.0",
        "addresses": 2097150,
        "wildcard": "0.31.255.255"
      },
      {
        "address": "/12",
        "subnet_mask": "255.240.0.0",
        "addresses": 1048574,
        "wildcard": "0.15.255.255"
      },
      {
        "address": "/13",
        "subnet_mask": "255.248.0.0",
        "addresses": 524286,
        "wildcard": "0.7.255.255"
      },
      {
        "address": "/14",
        "subnet_mask": "255.252.0.0",
        "addresses": 262142,
        "wildcard": "0.3.255.255"
      },
      {
        "address": "/15",
        "subnet_mask": "255.254.0.0",
        "addresses": 131070,
        "wildcard": "0.1.255.255"
      },
      {
        "address": "/16",
        "subnet_mask": "255.255.0.0",
        "addresses": 65534,
        "wildcard": "0.0.255.255"
      },
      {
        "address": "/17",
        "subnet_mask": "255.255.128.0",
        "addresses": 32766,
        "wildcard": "0.0.127.255"
      },
      {
        "address": "/18",
        "subnet_mask": "255.255.192.0",
        "addresses": 16382,
        "wildcard": "0.0.63.255"
      },
      {
        "address": "/19",
        "subnet_mask": "255.255.224.0",
        "addresses": 8190,
        "wildcard": "0.0.31.255"
      },
      {
        "address": "/20",
        "subnet_mask": "255.255.240.0",
        "addresses": 4094,
        "wildcard": "0.0.15.255"
      },
      {
        "address": "/21",
        "subnet_mask": "255.255.248.0",
        "addresses": 2046,
        "wildcard": "0.0.7.255"
      },
      {
        "address": "/22",
        "subnet_mask": "255.255.252.0",
        "addresses": 1022,
        "wildcard": "0.0.3.255"
      },
      {
        "address": "/23",
        "subnet_mask": "255.255.254.0",
        "addresses": 510,
        "wildcard": "0.0.1.255"
      },
      {
        "address": "/24",
        "subnet_mask": "255.255.255.0",
        "addresses": 254,
        "wildcard": "0.0.0.255"
      },
      {
        "address": "/25",
        "subnet_mask": "255.255.255.128",
        "addresses": 126,
        "wildcard": "0.0.0.127"
      },
      {
        "address": "/26",
        "subnet_mask": "255.255.255.192",
        "addresses": 62,
        "wildcard": "0.0.0.63"
      },
      {
        "address": "/27",
        "subnet_mask": "255.255.255.224",
        "addresses": 30,
        "wildcard": "0.0.0.31"
      },
      {
        "address": "/28",
        "subnet_mask": "255.255.255.240",
        "addresses": 14,
        "wildcard": "0.0.0.15"
      },
      {
        "address": "/29",
        "subnet_mask": "255.255.255.248",
        "addresses": 6,
        "wildcard": "0.0.0.7"
      },
      {
        "address": "/30",
        "subnet_mask": "255.255.255.252",
        "addresses": 2,
        "wildcard": "0.0.0.3"
      },
      {
        "address": "/31",
        "subnet_mask": "255.255.255.254",
        "addresses": "2*",
        "wildcard": "0.0.0.1"
      },
      {
        "address": "/32",
        "subnet_mask": "255.255.255.255",
        "addresses": 1,
        "wildcard": "0.0.0.0"
      }];

    this.populateOptions();

    if (this.fullAddressInputTarget.value) {
      this.setMask();
    }

    this.updateUI();
  }

  setMask() {
    let str = this.fullAddressInputTarget.value.split("/");
    if (str.length == 2 && str[str.length - 1]) {
      const maskValue = "/" + str[str.length - 1].trim();
      this.maskSelectTarget.value = maskValue;
      this.addressInputTarget.value = str[0].trim();
    }
    this.updateUI();
  }

  populateOptions() {
    this.optionsValue.forEach(option => {
      const opt = document.createElement('option');
      opt.value = option.address;
      opt.innerHTML = option.address;
      this.maskSelectTarget.appendChild(opt);
    });
  }

  updateUI() {
    const selectedOption = this.optionsValue.find(el => el.address === this.maskSelectTarget.value);
    if (selectedOption) {
      this.usableIpsTarget.textContent = selectedOption.addresses;
      this.maskTarget.textContent = selectedOption.subnet_mask;
      this.maskBitsTarget.textContent = selectedOption.address.substring(1);
      this.wildcardTarget.textContent = selectedOption.wildcard;
    }
  }

  handleInput(event) {
    const inputValue = this.addressInputTarget.value;

    // Store the current input for processing on blur
    this._currentInput = inputValue;

    // Regular handling for normal input
    const address = this.addressInputTarget.value;
    const mask = this.maskSelectTarget.value;
    if (address && mask) {
      this.fullAddressInputTarget.value = `${address}${mask}`;
    }
    this.updateUI();
  }

  handleBlur(event) {
    // Process CIDR notation on blur (when user has finished typing)
    const inputValue = this._currentInput || this.addressInputTarget.value;

    // Check if the input contains a CIDR notation (e.g., "/23")
    if (inputValue.includes('/')) {
      const parts = inputValue.split('/');
      if (parts.length === 2) {
        const ipAddress = parts[0].trim();
        const cidrMask = '/' + parts[1].trim();

        // Check if the mask exists in our options
        const maskExists = this.optionsValue.some(option => option.address === cidrMask);

        if (maskExists) {
          // Update the IP address input with just the address part
          this.addressInputTarget.value = ipAddress;

          // Set the mask dropdown to the specified value
          this.maskSelectTarget.value = cidrMask;

          // Update the hidden field with the combined value
          this.fullAddressInputTarget.value = `${ipAddress}${cidrMask}`;

          // Update the UI to reflect the new mask
          this.updateUI();

          // Clear the stored input
          this._currentInput = null;

          return;
        }
      }
    }

    // If we didn't process a CIDR notation, just update the hidden field
    const address = this.addressInputTarget.value;
    const mask = this.maskSelectTarget.value;
    if (address && mask) {
      this.fullAddressInputTarget.value = `${address}${mask}`;
    }
    this.updateUI();

    // Clear the stored input
    this._currentInput = null;
  }

  handleSelectChange() {
    const address = this.addressInputTarget.value;
    const mask = this.maskSelectTarget.value;
    if (address && mask) {
      this.fullAddressInputTarget.value = `${address}${mask}`;
    }
    this.updateUI();
  }
}
