import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "item", "openedItem"]
  static values = {
    data: Array,
    openItem: { type: Number, default: 0 },
    openIndex: { type: Number, default: -1 }
  }

  connect() {
    this.render()
  }

  render() {
    if (this.openItemValue === 0) {
      this.renderList()
    } else {
      this.renderOpenedItem()
    }
  }

  open(event) {
    event.preventDefault()
    const item = event.currentTarget.closest("[data-item-id]")
    this.openItemValue = parseInt(item.dataset.itemId)
    this.openIndexValue = parseInt(item.dataset.itemIndex)
    this.render()
  }

  close(event) {
    event.preventDefault()
    this.openItemValue = 0
    this.openIndexValue = -1
    this.render()
  }

  cffClass(shade) {
    return `custom-fast-fact custom-fast-fact--${shade}`
  }

  renderList() {
    const container = this.containerTarget
    container.innerHTML = `
      <div class="custom-fast-facts">
        ${this.dataValue.map((d, index) => `
          <div class="${this.cffClass(d.shade)}" data-item-id="${d.id}" data-item-index="${index}">
            <div class="custom-fast-fact__header">
              ${d.image_url
                ? `<img src="${d.image_url}" class="custom-fast-fact__img"/>`
                : `<i class="${d.icon || 'fas fa-info-circle'}"></i>`
              }
              <h1>${d.title}</h1>
            </div>
            <div class="custom-fast-fact__content">
              <p>${d.message}</p>
            </div>
            ${d.content ? `
              <div class="custom-fast-fact__footer">
                <a href="#" data-action="custom-fast-facter#open" class="button button--tertiary">
                  Open <i class="fas fa-arrow-right"></i>
                </a>
              </div>
            ` : ''}
            ${d.content_link ? `
              <div class="custom-fast-fact__footer">
                <a href="${d.content_link}" class="button button--tertiary" target="_blank">
                  Open Link <i class="fas fa-external-link-alt"></i>
                </a>
              </div>
            ` : ''}
          </div>
        `).join('')}
      </div>
    `
  }

  renderOpenedItem() {
    const container = this.containerTarget
    const item = this.dataValue[this.openIndexValue]

    if (!item) return

    container.innerHTML = `
      <a href="#" class="custom-fast-facts__exit" data-action="custom-fast-facter#close">
        <i class="fas fa-arrow-left"></i>Back
      </a>
      <div class="${this.cffClass(item.shade)}">
        <div class="custom-fast-fact__header">
          ${item.image_url
            ? `<img src="${item.image_url}" class="custom-fast-fact__img"/>`
            : `<i class="${item.icon || 'fas fa-info-circle'}"></i>`
          }
          <h1>${item.title}</h1>
        </div>
        <div class="custom-fast-fact__opened">
          ${item.content}
        </div>
      </div>
    `
  }
}
