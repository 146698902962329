<template>
  <div class='asset-sidebar__group' v-if='(can_create || uploads.length > 0)' @keydown.esc="close">
    <header>
      <h2><widget-toggler name="Files">Files ({{uploads.length}})</widget-toggler></h2>
    </header>
    <div class="asset-sidebar__group-content asset-sidebar__group-content--Files">
      <div v-show='can_create'>
        <vue-dropzone ref="myVueDropzone" id="dropzone" @vdropzone-success="vsuccess" :options="dropzoneOptions"></vue-dropzone>
      </div>
      <div class='asset-sidebar__box' v-if='uploads.length > 0'>
        <section v-for="(upload, index) in uploads">

          <template v-if='isEdit && (editIndex === index)'>
            <form @submit.prevent.stop='saveEdit(upload.id)' class='asset-sidebar__form'>
              <input type='text' v-model='newName' :id='"fileManagerInput" + index' placeholder="New name...">
              <div class='button-group'>
                <a href='#' @click.prevent.stop='saveEdit(upload.id)' class='button button--small button--primary'>Save</a>
                <a href='#' @click.prevent.stop='cancelEdit' class='button button--small button--secondary'>Cancel</a>
              </div>
            </form>
          </template>

          <template v-if='editIndex != index'>
            <h3>
              <small class='asset-sidebar__filetype'>{{upload.ext || "?"}}</small>
              <template v-if='upload.ext == "pdf"'>
                <a href='#' @click.prevent.stop='open(index)' :title='upload.size + " - Uploaded " + upload.created_date'>{{upload.name}}</a>
              </template>
              <template v-if='upload.ext != "pdf"'>
                <a :href='upload.url' :title='upload.size + " - Uploaded " + upload.created_date'>{{upload.name}}</a>
              </template>
            </h3>
            <div v-if='can_create && upload.id' class='button-group button-group--condensed'>
              <a href='' @click.prevent.stop='startEdit(index, upload.name)' class='button button--icon button--icon--small'><i class="fas fa-edit"></i></a>
              <a href='#' @click.prevent="deleteupload(upload.id, index)" class='button button--icon button--icon--small button--icon--danger'><i class='fas fa-trash-can'></i></a>
            </div>
          </template>
        </section>

        <Teleport to="body">
          <div class='photo-manager__bg' v-show='showIndex != -1'>
            <div class='photo-manager__modal' v-if='showIndex != -1'>
              <header>
                <div class='photo-manager__name'>
                  <i class="fas fa-file-pdf"></i>
                  <div>
                    <div class="photo-manager__name-title">
                      <h2 class="photo-manager__company-name">{{uploads[showIndex].name}}</h2>
                    </div>
                  </div>
                </div>
                <div class="button-toolbar">
                  <a :href='uploads[showIndex].url' ><i class='far fa-download'></i></a>
                  <a href='#' @click.prevent.stop='close'><i class='far fa-times'></i></a>
                </div>
              </header>
              <main>
                <iframe :src="uploads[showIndex].url + '?inline=true'" type="application/pdf" width="100%" height="750px"></iframe>
              </main>
            </div>
          </div>
        </Teleport>

      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import flash from "../lib/flashes.js";
import WidgetToggler from "./WidgetToggler.vue"
import Teleport from 'vue2-teleport';

export default {
  props: ["base_url", "type", "show",
          "url", "uploadable_id", "uploadable_type",
          "can_create", "max_file_size"],
  components: {
    Teleport,
    vueDropzone: vue2Dropzone,
    WidgetToggler
  },
  mounted () {

    var that = this;

    fetch(that.url)
    .then((resp) => resp.json())
    .then(function(data) {
      that.uploads = data;
    })
  },
  methods: {
    vsuccess ( file, response) {
      var that = this;

      fetch(that.url)
      .then((resp) => resp.json())
      .then(function(data) {
        that.uploads = data;
        flash('File added','success')
      })
    },
    startEdit (index, name) {
      this.isEdit = true;
      this.editIndex = index;
      this.newName = name;

      this.$nextTick(() => document.querySelector("#fileManagerInput" + index).focus())
    },
    addFile () {
      if (this.add === true) {
        this.add = false;
        return;
      }
      this.add = true
    },
    isImage(mime) {
      return false
    },
    deleteupload (id, index) {
      var that = this;

      if(confirm('Are you sure?')) {
        that.$api.delete(`${that.base_url}/uploads/${id}`).then(function (response) {
          that.uploads.splice(index, 1);
        }).catch(error => {
          console.log(error)
        })
      }
    },
    cancelEdit () {
      this.editIndex = -1;
      this.newName = "";
      this.isEdit = false;
    },
    clearEdit () {
      this.editIndex = -1;
      this.newName = "";
      this.isEdit = false;
    },
    saveEdit (id) {
      var that = this;
      that.$api.put(`${that.base_url}/uploads/${id}`, { name: that.newName}).then(function (response) {
        fetch(that.url)
        .then((resp) => resp.json())
        .then(function(data) {
          that.uploads = data;
          flash('File updated','success')
          that.clearEdit()
        })
      }).catch(error => {
        console.log(error)
      })
    },
    close () {
      this.showIndex = -1;
    },
    open (index) {
      this.showIndex = index;
    }
  },
  data: function () {
    return {
      dropzoneOptions: {
          url: '/uploads',
          timeout: 90000,
          parallelUploads: 10,
          thumbnailWidth: 150,
          dictDefaultMessage: "Drop files here to upload <br><br>100 MB max",
          maxFilesize: this.max_file_size,
          params: {
            uploadable_id: this.uploadable_id,
            uploadable_type: this.uploadable_type
          }
      },
      isEdit: false,
      add: false,
      editIndex: -1,
      newName: "",
      uploads: [],
      isShown: true,
      showIndex: -1
    }
  }
}
</script>

