import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["search"]

  connect() {
    this.lastValue = this.searchTarget.value

    // Create a debounced version of the search function
    this.debouncedSearch = this.debounce(() => {
      // Only search if the value has actually changed
      if (this.lastValue !== this.searchTarget.value) {
        this.lastValue = this.searchTarget.value
        this.search()
      }
    }, 250) // 250ms delay
  }

  debounce(func, wait) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  search() {
    // Handle folders visibility
    var folders = document.querySelector(".index__folders");
    if (folders) {
      if (this.searchTarget.value) {
        folders.style.display = "none";
      } else {
        folders.style.display = "block";
      }
    }
    // Handle search
    this.searchTarget.form.requestSubmit()
  }
}
