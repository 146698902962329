import { Controller } from "@hotwired/stimulus"
import list from "../lib/word-list.js"

export default class extends Controller {
  static targets = [
    "changePasswordButton", "passwordForm", "passwordInput", "passwordHidden",
    "generator", "lengthGroup", "lengthInput", "lengthSlider",
    "wordGroup", "numWordsInput", "numWordsSlider", "wordOptions",
    "alternatingCase", "includeNumber", "separator"
  ]

  connect() {
    this.possible = "0123456789![]{}()%&*$#^<>~@|abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    this.length = 12
    this.numWords = 4

    // Set initial state based on the checked radio button
    const checkedRadio = this.element.querySelector('input[name="possible"]:checked');
    if (checkedRadio) {
      if (checkedRadio.value === 'word') {
        this.wordGroupTarget.style.display = 'block';
        this.lengthGroupTarget.style.display = 'none';
      }
    }
  }

  toggleChangePassword(event) {
    event.preventDefault()
    this.changePasswordButtonTarget.style.display = 'none'
    this.passwordFormTarget.style.display = 'block'
  }

  updateHiddenInput() {
    this.passwordHiddenTarget.value = this.passwordInputTarget.value
  }

  viewPassword(event) {
    event.preventDefault()
    this.passwordInputTarget.type = this.passwordInputTarget.type === "password" ? "text" : "password"
  }

  toggleGenerator(event) {
    event.preventDefault()
    this.generatorTarget.style.display = this.generatorTarget.style.display === 'none' ? 'block' : 'none'

    var text =  this.generate()
    if (this.passwordInputTarget.value.length === 0) {
      this.passwordInputTarget.type = "text";
      this.passwordInputTarget.value = text
      this.updateHiddenInput()
    }
  }

  generate() {
    let text = ""
    const possible = document.querySelector('input[name="possible"]:checked').value

    if (possible === "word") {
      const wordArray = []
      for (let i = 0; i < this.numWordsInputTarget.value; i++) {
        let chosen = this.chooseRandomWord()
        if (i % 2 === 0 && this.alternatingCaseTarget.checked) {
          chosen = chosen.toUpperCase()
        }
        wordArray.push(chosen)
      }
      text = wordArray.join(this.separatorTarget.value)
      if (this.includeNumberTarget.checked) {
        text += Math.floor(Math.random() * 10)
      }
    } else {
      for (let i = 0; i < this.lengthInputTarget.value; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }
    }

    this.passwordInputTarget.value = text
    this.updateHiddenInput()

    // Add blink effect
    this.passwordInputTarget.classList.add('password-blink')
    setTimeout(() => {
        this.passwordInputTarget.classList.remove('password-blink')
    }, 500) // Duration should match the CSS animation duration

    if (this.passwordInputTarget.type === "password") {
        this.passwordInputTarget.type = "text"
    }
    return text
  }

  chooseRandomWord() {
    const finish = list.names.length - 1
    return list.names[Math.floor(Math.random() * finish)].replace(/\s/g, '').toLowerCase()
  }

  toggleWordOptions(event) {
    const wordGroup = this.wordGroupTarget;
    const lengthGroup = this.lengthGroupTarget;

    if (event.target.value === 'word') {
      wordGroup.style.display = 'block';
      lengthGroup.style.display = 'none';
    } else {
      wordGroup.style.display = 'none';
      lengthGroup.style.display = 'block';
    }
  }

  updateLengthFromNumber(event) {
    const min = parseInt(event.target.min);
    const max = parseInt(event.target.max);
    let value = parseInt(event.target.value);

    value = Math.min(Math.max(value, min), max);

    this.lengthInputTarget.value = value;
    this.lengthSliderTarget.value = value;
    this.generate();
  }

  updateLengthFromSlider(event) {
    const min = parseInt(event.target.min);
    const max = parseInt(event.target.max);
    let value = parseInt(event.target.value);

    value = Math.min(Math.max(value, min), max);

    this.lengthInputTarget.value = value;
    this.lengthSliderTarget.value = value;
    this.generate();
  }

  updateNumWordsFromNumber(event) {
    const min = parseInt(event.target.min);
    const max = parseInt(event.target.max);
    let value = parseInt(event.target.value);

    value = Math.min(Math.max(value, min), max);

    this.numWordsInputTarget.value = value;
    this.numWordsSliderTarget.value = value;
    this.generate();
  }

  updateNumWordsFromSlider(event) {
    const min = parseInt(event.target.min);
    const max = parseInt(event.target.max);
    let value = parseInt(event.target.value);

    value = Math.min(Math.max(value, min), max);

    this.numWordsInputTarget.value = value;
    this.numWordsSliderTarget.value = value;
    this.generate();
  }
}
