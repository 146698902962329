import { Controller } from "@hotwired/stimulus"
import flash from "../lib/flashes.js";

export default class extends Controller {
  static targets = ["bridgeLink"]
  static values = {
    tokenUrl: String
  }
  async startBridge(event) {
    event.preventDefault()
    this.setStatus("Connecting...")

    try {

      const response = await fetch(this.tokenUrlValue, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.getCSRFToken()
        }
      })

      if (!response.ok) {
        flash(`Failed to connect: ${response.status}`, "error")
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      const authToken = data.auth_token
      const hostname = data.hostname
      const jwtToken = data.jwt_token

      this.setStatus(`Connected!`)

      // // Update the bridgeLink href with the auth token
      const currentHref = this.bridgeLinkTarget.getAttribute('href')
      this.bridgeLinkTarget.setAttribute('href', `${hostname}/bridges/host_sessions/sso_login?auth_token=${authToken}&jwt_token=${jwtToken}`)

      // // Auto-click the bridge link
      this.bridgeLinkTarget.click()

      // Reset the bridge link href
      this.bridgeLinkTarget.setAttribute('href', currentHref)

      this.setStatus(`Redirecting...`)
    } catch (error) {
      console.error('Error:', error)
      this.setStatus(`Error: ${error.message}`)
    }
  }

  setStatus(message) {
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = message
    }
  }

  getCSRFToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
