import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select';

export default class extends Controller {
  static values = {
    url: String,
    recordType: { type: String, default: "Tag"},
    selected: { type: Array},
    placeholder: {type: String, default: "Select or create tags"},
  };
  static targets = ["select"];

  connect() {
    this.initializeTomSelect();
  }

  disconnect() {
    this.destroyTomSelect();
  }

  initializeTomSelect() {
    if (!this.element) return;

    this.select = new TomSelect(this.selectTarget, {
      plugins: ['remove_button'],
      valueField: 'name',
      labelField: 'name',
      searchField: 'name',
      create: true,
      load: (query, callback) => this.loadOptions(query, callback),
      preload: 'focus',
      placeholder: this.placeholderValue,
      items: this.selectedValue,
      onItemAdd: () => {
        this.select.setTextboxValue('');
      }
    });
  }

  loadOptions(query, callback) {
    const url = `${this.urlValue}?q=${encodeURIComponent(query)}&type=${this.recordTypeValue}&include_archived=false`;
    fetch(url)
      .then(response => response.json())
      .then(data => callback(data))
      .catch(() => callback());
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}
