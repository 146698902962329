import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = ["content"]
  static values = {
    undismissable: Boolean
  }

  connect() {
    this.isExpanded = false
    document.addEventListener('keydown', this.handleKeydown.bind(this))
    this.element.addEventListener('mousedown', this.handleMouseDown.bind(this))
    this.element.addEventListener('click', this.handleOutsideClick.bind(this))

    // Only trigger slide-in animation for sidepanel modals
    if (this.contentTarget.classList.contains('manage_modal--sidepanel')) {
      requestAnimationFrame(() => {
        this.element.classList.add('is-visible')
      })
    }

    // Initialize tippy tooltips for modal content
    tippy('[data-tippy-content]', {
      appendTo: () => this.element
    })
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeydown.bind(this))
    this.element.removeEventListener('mousedown', this.handleMouseDown.bind(this))
    this.element.removeEventListener('click', this.handleOutsideClick.bind(this))
  }

  handleMouseDown(event) {
    // Only track mouse position if modal is dismissable
    if (!this.undismissableValue) {
      this.mouseDownInsideModal = this.contentTarget.contains(event.target)
    }
  }

  handleOutsideClick(event) {
    // Only close if not undismissable and both mousedown and click were outside the modal
    if (!this.undismissableValue &&
        !this.mouseDownInsideModal &&
        !this.contentTarget.contains(event.target) &&
        event.target === this.element) {
      this.hideModal()
    }
  }

  handleKeydown(event) {
    // Only handle Escape if not undismissable
    if (event.key === "Escape" && !this.undismissableValue) {
      this.hideModal()
    }
  }

  cleanup() {
    // Remove the content from the turbo frame when hiding
    const frame = document.getElementById('sidepanel')
    if (frame) {
      frame.innerHTML = ''
    }
  }

  hideModal() {
    this.cleanup()
    if (this.contentTarget.classList.contains('manage_modal--sidepanel')) {
      // Add animation before removing
      this.element.classList.remove('is-visible')

      // Wait for animation to complete before removing
      this.element.addEventListener('transitionend', () => {
        this.element.parentElement.removeAttribute("src")
        this.element.remove()
      }, { once: true })
    } else {
      // For non-sidepanel modals, remove immediately
      this.element.parentElement.removeAttribute("src")
      this.element.remove()
    }
  }

  expandModal(event) {
    event.preventDefault()
    this.isExpanded = !this.isExpanded

    if (this.isExpanded) {
      this.contentTarget.classList.add('manage_modal--expanded')
      event.currentTarget.innerHTML = "<i class='far fa-arrow-down-left-and-arrow-up-right-to-center'></i>"
    } else {
      this.contentTarget.classList.remove('manage_modal--expanded')
      event.currentTarget.innerHTML = "<i class='far fa-arrow-up-right-and-arrow-down-left-from-center'></i>"
    }
  }

  submit(event) {
    const form = event.currentTarget.closest('form');

    if (form) {
      form.submit();
    }
  }
}
