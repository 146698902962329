import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["folder", "empty", "sidebar", "toggleButton", "folderContent", "folderIcon", "emptyToggle", "emptyIcon", "emptyText", "sidebarItem", "categoryTitle"]
  static values = {
    sidebarLevel: { type: Number, default: 0 },
    hideEmpty: { type: Boolean, default: false },
    hides: { type: Array, default: [] }
  }

  connect() {
    this.loadStoredPreferences()
    this.calculateSidebarWidth(this.sidebarLevelValue)
    this.updateToggleButtons()
    this.updateFolderVisibility()
    this.updateEmptyToggleUI()
    this.updateEmptyItems()
  }

  isFolderHidden(folderId) {
    if (this.sidebarLevelValue == 1) {
      return false
    }
    return this.hidesValue.includes(folderId)
  }

  toggleFolder(event) {
    event.preventDefault()
    const folderId = event.currentTarget.dataset.folderId

    if (this.isFolderHidden(folderId)) {
      this.hidesValue = this.hidesValue.filter(id => id !== folderId)
    } else {
      this.hidesValue = [...this.hidesValue, folderId]
    }

    localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hidesValue))
    this.updateFolderVisibility()
  }

  updateFolderVisibility() {
    this.folderContentTargets.forEach(content => {
      const folderId = content.dataset.folderId
      const folderIcon = this.folderIconTargets.find(icon =>
        icon.dataset.folderId === folderId
      )

      if (this.isFolderHidden(folderId)) {
        content.style.display = 'none'
        folderIcon.classList.remove('fa-chevron-down')
        folderIcon.classList.add('fa-chevron-right')
      } else {
        content.style.display = ''
        folderIcon.classList.remove('fa-chevron-right')
        folderIcon.classList.add('fa-chevron-down')
      }
    })
  }

  sidebarLevelValueChanged() {
    this.updateToggleButtons()
    this.updateFolderVisibility()
  }

  updateToggleButtons() {
    this.toggleButtonTargets.forEach(button => {
      const showAtLevel = parseInt(button.dataset.showAtLevel)
      button.style.display = this.sidebarLevelValue === showAtLevel ? '' : 'none'
    })
  }

  loadStoredPreferences() {
    // Load folder hides
    try {
      const storedHides = localStorage.getItem('huduSidebarFolderHides')
      if (storedHides) {
        this.hidesValue = JSON.parse(storedHides)
      }
    } catch(e) { }

    // Load hide empty preference
    if (!this.hasNoHideBlankValue) {
      try {
        if (localStorage.getItem('huduHideEmptySidebarAssets')) {
          this.hideEmptyValue = true
        }
      } catch(e) { }
    }

    // Load sidebar level
    try {
      const level = localStorage.getItem('huduSidebarResizeLevel')
      if (level) {
        this.sidebarLevelValue = parseInt(level)
        this.calculateSidebarWidth(this.sidebarLevelValue)
      }
    } catch(e) { }
  }

  resizeSidebar(event) {
    event.preventDefault()
    const level = parseInt(event.currentTarget.dataset.level)
    this.sidebarLevelValue = level
    localStorage.setItem('huduSidebarResizeLevel', level)
    this.calculateSidebarWidth(level)
  }

  toggleEmpty(event) {
    event.preventDefault()
    this.hideEmptyValue = !this.hideEmptyValue

    if (this.hideEmptyValue) {
      localStorage.setItem('huduHideEmptySidebarAssets', true)
    } else {
      localStorage.removeItem('huduHideEmptySidebarAssets')
    }

    this.updateEmptyToggleUI()
    this.updateEmptyItems()
  }

  updateEmptyToggleUI() {
    if (!this.hasEmptyToggleTarget) return

    const icon = this.emptyIconTarget
    const text = this.emptyTextTarget

    if (this.hideEmptyValue) {
      icon.classList.remove('fa-eye-slash')
      icon.classList.add('fa-eye')
      text.textContent = 'Show Empty Items'
    } else {
      icon.classList.remove('fa-eye')
      icon.classList.add('fa-eye-slash')
      text.textContent = 'Hide Empty Items'
    }
  }

  updateEmptyItems() {
    // First, handle individual items
    this.sidebarItemTargets.forEach(item => {
      const countElement = item.querySelector('.count')
      if (!countElement) return

      const count = countElement.textContent
      if (this.hideEmptyValue && count === '0') {
        item.style.display = 'none'
      } else {
        item.style.display = ''
      }
    })

    // Then, handle categories
    this.categoryTitleTargets.forEach(categoryTitle => {
      const folderId = categoryTitle.dataset.folderId
      const folderContent = this.folderContentTargets.find(content =>
        content.dataset.folderId === folderId
      )

      if (!folderContent) return

      // Count visible items in this category
      const visibleItems = Array.from(folderContent.querySelectorAll('.sidebar__item'))
        .filter(item => item.style.display !== 'none')
        .length

      // Hide category if it has no visible items
      categoryTitle.style.display = visibleItems > 0 ? '' : 'none'

      // Update folder content visibility based on both visible items and folder state
      if (visibleItems === 0) {
        folderContent.style.display = 'none'
      } else {
        // Only show if the folder isn't manually collapsed
        folderContent.style.display = this.isFolderHidden(folderId) ? 'none' : ''
      }
    })

    // Finally, update folder icons to match their state
    this.updateFolderVisibility()
  }

  calculateSidebarWidth(level) {
    if (!this.hasSidebarTarget) return

    if (level == 1) {
      this.sidebarTarget.classList.add("sidebar--skinny")
      document.documentElement.style.setProperty('--sidebarwidth', '85px')
    } else if (level == 2) {
      this.sidebarTarget.classList.remove("sidebar--skinny")
      document.documentElement.style.setProperty('--sidebarwidth', '380px')
    } else {
      this.sidebarTarget.classList.remove("sidebar--skinny")
      document.documentElement.style.setProperty('--sidebarwidth', '235px')
    }
  }
}
