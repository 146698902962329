import { Controller } from "@hotwired/stimulus"
import flash from "../lib/flashes"

export default class extends Controller {
  static targets = [
    "credentials",
    "decryptInput",
    "copyStep",
    "passphraseStep",
    "passphraseInput",
    "tokenInput",
    "checkCredentialsGroup",
    "submitGroup",
    "jwtSecretInput",
    "clientSecretInput",
    "clientIdInput",
    "hostnameInput"
  ]

  connect() {
    if (!this.isSupported()) {
      console.warn('Your browser might not support all encryption features')
      return
    }

    // Automatically show the modal if the button is present
    const modalButton = document.querySelector('[data-turbo-frame="modal"]')
    if (modalButton) {
      modalButton.click()
    }
  }

  isSupported() {
    return typeof crypto !== 'undefined' &&
           typeof TextEncoder !== 'undefined' &&
           typeof Promise !== 'undefined' &&
           typeof async function(){} === 'function'
  }

  async encrypt(password, data) {
    if (!data) {
      throw new Error('No data provided for encryption');
    }

    if (!password) {
      throw new Error('No password provided for encryption');
    }

    // Convert password to key
    const encoder = new TextEncoder();
    const salt = crypto.getRandomValues(new Uint8Array(16));

    try {
      const key = await crypto.subtle.importKey(
        'raw',
        encoder.encode(password),
        { name: 'PBKDF2', hash: 'SHA-256' },
        false,
        ['deriveKey']
      );

      const derivedKey = await crypto.subtle.deriveKey(
        { name: 'PBKDF2', salt, iterations: 100000, hash: 'SHA-256' },
        key,
        { name: 'AES-GCM', length: 256 },
        false,
        ['encrypt']
      );

      // Encrypt
      const iv = crypto.getRandomValues(new Uint8Array(12));
      const encodedData = encoder.encode(data);
      const encryptedContent = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        derivedKey,
        encodedData
      );

      // Combine salt + iv + encrypted content
      const encryptedArray = new Uint8Array([
        ...salt,
        ...iv,
        ...new Uint8Array(encryptedContent)
      ]);

      return btoa(String.fromCharCode(...encryptedArray));
    } catch (error) {
      console.error('Encryption failed:', error);
      throw new Error('Encryption failed: ' + error.message);
    }
  }

  async decrypt(password, encryptedData) {
    try {
      // Decode base64
      const encryptedArray = new Uint8Array(
        atob(encryptedData).split('').map(c => c.charCodeAt(0))
      )

      // Extract salt, iv, and encrypted content
      const salt = encryptedArray.slice(0, 16)
      const iv = encryptedArray.slice(16, 28)
      const encryptedContent = encryptedArray.slice(28)

      // Convert password to key
      const encoder = new TextEncoder()
      const key = await crypto.subtle.importKey(
        'raw',
        encoder.encode(password),
        { name: 'PBKDF2', hash: 'SHA-256' },
        false,
        ['deriveKey']
      )

      const derivedKey = await crypto.subtle.deriveKey(
        { name: 'PBKDF2', salt, iterations: 100000, hash: 'SHA-256' },
        key,
        { name: 'AES-GCM', length: 256 },
        false,
        ['decrypt']
      )

      // Decrypt
      const decryptedContent = await crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        derivedKey,
        encryptedContent
      )

      return new TextDecoder().decode(decryptedContent)
    } catch (error) {
      console.error('Decryption failed:', error)
      throw new Error('Failed to decrypt credentials. Please check your passphrase.')
    }
  }

  async encryptCredentials(event) {
    const password = document.getElementById('encryption-password').value;
    const hiddenInput = document.getElementById('bridge-credentials');
    const copyInput = document.getElementById('bridge-credentials-copy');

    if (!password) {
      alert('Please enter a password');
      return;
    }

    if (!hiddenInput || !copyInput) {
      console.error('Could not find bridge credentials input');
      return;
    }

    try {
      const dataToEncrypt = hiddenInput.textContent.trim();
      const encrypted = await this.encrypt(password, dataToEncrypt);

      // Try to find the actual input element
      const actualInput = copyInput.querySelector('input') || copyInput;
      actualInput.value = encrypted;

      // Hide password section and show copy section
      this.passphraseStepTarget.style.display = 'none';
      this.copyStepTarget.style.display = 'block';
    } catch (error) {
      console.error('Encryption failed:', error);
      alert('Failed to encrypt credentials: ' + error.message);
    }
  }

  async decryptCredentials(event) {
    event.preventDefault()

    const passphrase = this.passphraseInputTarget.value
    const token = this.tokenInputTarget.value

    if (!passphrase || !token) {
      alert('Please enter both passphrase and bridge token')
      return
    }

    try {
      const decrypted = await this.decrypt(passphrase, token)
      const credentials = JSON.parse(decrypted)

      // Verify all required fields exist in the decrypted data
      if (!credentials.jwt_secret || !credentials.client_secret ||
          !credentials.client_id || !credentials.hostname) {
        throw new Error('Incomplete credentials in bridge token')
      }

      // Fill in the hidden fields
      this.jwtSecretInputTarget.value = credentials.jwt_secret
      this.clientSecretInputTarget.value = credentials.client_secret
      this.clientIdInputTarget.value = credentials.client_id
      this.hostnameInputTarget.value = credentials.hostname

      // Hide the check credentials button and show the submit button
      this.checkCredentialsGroupTarget.style.display = 'none'
      this.submitGroupTarget.style.display = 'flex'

      flash("Credentials validated", "success")
    } catch (error) {
      console.error('Decryption error:', error)
      flash("Incorrect passphrase or bridge token", "error")
    }
  }
}
