import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["endpoint", "apiVersion", "model", "apiKey", "endpointHint", "modelLabel"]

  connect() {
    this.toggleFields()
  }

  toggleFields() {
    const providerSelect = document.querySelector('select[name*="[ai_provider]"]')
    const currentProvider = providerSelect.value.toLowerCase()

    // Hide all provider-specific hints first
    if (this.hasProviderHintTarget) {
      this.providerHintTargets.forEach(hint => {
        hint.style.display = 'none'
      })
    }

    // Show the hint for the current provider
    const currentHint = document.querySelector(`[data-provider-hint="${currentProvider}"]`)
    if (currentHint) {
      currentHint.style.display = 'block'
    }

    // Handle API key field visibility and required status
    if (this.hasApiKeyTarget) {
      const apiKeyGroup = this.apiKeyTarget.closest('.form-group')
      const apiKeyLabel = apiKeyGroup.querySelector('label')

      if (currentProvider === 'ollama') {
        apiKeyGroup.style.display = 'none'
        if (apiKeyLabel) apiKeyLabel.classList.remove('required')
      } else {
        apiKeyGroup.style.display = 'block'
        if (apiKeyLabel) apiKeyLabel.classList.add('required')
      }
    }

    // Handle endpoint field visibility and hint
    if (this.hasEndpointTarget) {
      const endpointGroup = this.endpointTarget.closest('.form-group')
      const endpointLabel = endpointGroup.querySelector('label')
      const endpointHint = this.hasEndpointHintTarget ? this.endpointHintTarget : endpointGroup.querySelector('.hint')

      if (currentProvider === 'openai') {
        endpointGroup.style.display = 'none'
        if (endpointLabel) endpointLabel.classList.remove('required')
      } else {
        endpointGroup.style.display = 'block'
        if (endpointLabel) endpointLabel.classList.add('required')

        // Update endpoint hint based on provider
        if (endpointHint) {
          switch (currentProvider) {
            case 'azure_openai':
              endpointHint.textContent = "Include the deployment name (e.g., https://example.openai.azure.com/openai/deployments/deployment-name)."
              break
            case 'ollama':
              endpointHint.textContent = "Use http://localhost:11434 for local or your server URL for remote."
              break
            case 'grok':
              endpointHint.textContent = "Enter the Grok API endpoint URL."
              break
          }
        }
      }
    }

    // Handle API version field visibility
    if (this.hasApiVersionTarget) {
      const apiVersionGroup = this.apiVersionTarget.closest('.form-group')
      const apiVersionLabel = apiVersionGroup.querySelector('label')

      if (currentProvider === 'azure_openai') {
        apiVersionGroup.style.display = 'block'
        if (apiVersionLabel) apiVersionLabel.classList.add('required')
      } else {
        apiVersionGroup.style.display = 'none'
        if (apiVersionLabel) apiVersionLabel.classList.remove('required')
      }
    }

    // Handle model field visibility, placeholder, and required status
    if (this.hasModelTarget) {
      const modelField = this.modelTarget
      const modelGroup = modelField.closest('.form-group')
      const modelLabel = this.hasModelLabelTarget ? this.modelLabelTarget : modelGroup.querySelector('label')

      // Show/hide model field based on provider
      if (currentProvider === 'azure_openai') {
        modelGroup.style.display = 'none'
        if (modelLabel) modelLabel.classList.remove('required')
      } else {
        modelGroup.style.display = 'block'

        // Update placeholder, hint, and required status based on provider
        const modelHint = modelGroup.querySelector('.hint')

        // Set required status
        if (currentProvider === 'ollama') {
          if (modelLabel) modelLabel.classList.add('required')
        } else {
          if (modelLabel) modelLabel.classList.remove('required')
        }

        switch (currentProvider) {
          case 'openai':
            modelField.placeholder = "gpt-4"
            if (modelHint) modelHint.textContent = "Optional. Defaults to gpt-4 if not specified."
            break
          case 'ollama':
            modelField.placeholder = "llama2"
            if (modelHint) modelHint.textContent = "Required. Enter the name of your Ollama model (e.g., llama2, llama3.2, etc.)."
            break
          case 'grok':
            modelField.placeholder = "grok-2-latest"
            if (modelHint) modelHint.textContent = "Optional. Defaults to grok-2-latest if not specified."
            break
        }
      }
    }
  }
}
