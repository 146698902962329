import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "labelInput", "fieldType", "fieldTypeLabel",
    "preferencesHeader", "listSelectGroup", "showInListGroup",
    "assetLinkGroup", "numberGroup", "addressGroup",
    "dateGroup", "requiredGroup", "dropdownGroup",
    "hintGroup", "multipleOptions", "deviceDiscoveryGroup"
  ]

  static values = {
    lists: Array,
    assetTypes: Array,
    mapboxIntegration: Boolean,
    companyStructureName: String
  }

  connect() {
    this.updateFieldTypeDisplay()
  }

  changeFieldType(event) {
    const selectedType = event.target.value
    this.fieldTypeTarget.value = selectedType
    this.updateFieldTypeDisplay()
  }

  updateFieldTypeDisplay() {
    const currentType = this.fieldTypeTarget.value

    // Update selected state of field type labels
    this.fieldTypeLabelTargets.forEach(label => {
      const fieldType = label.dataset.fieldType
      label.classList.toggle("selected", fieldType === currentType)
    })

    // Show/hide preference sections based on type
    this.preferencesHeaderTarget.classList.toggle("hidden", currentType === "Heading")

    this.listSelectGroupTarget.classList.toggle("hidden", currentType !== "ListSelect")

    this.showInListGroupTarget.classList.toggle("hidden",
      ["Heading", "RichText", "Embed"].includes(currentType))

    this.assetLinkGroupTarget.classList.toggle("hidden",
      !["AssetLink", "AssetTag"].includes(currentType))

    this.numberGroupTarget.classList.toggle("hidden", currentType !== "Number")

    this.addressGroupTarget.classList.toggle("hidden",
      currentType !== "AddressData" || !this.mapboxIntegrationValue)

    this.dateGroupTarget.classList.toggle("hidden", currentType !== "Date")

    this.requiredGroupTarget.classList.toggle("hidden",
      ["Heading", "CheckBox"].includes(currentType))

    this.dropdownGroupTarget.classList.toggle("hidden", currentType !== "Dropdown")

    this.hintGroupTarget.classList.toggle("hidden", currentType === "Heading")

    this.deviceDiscoveryGroupTarget.classList.toggle("hidden",
      !["Text", "RichText"].includes(currentType))
  }

  updateListId(event) {
    // Handle list selection change if needed
  }

  updateLinkableId(event) {
    // Handle asset type selection change if needed
  }
}
