import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["reservedMessage", "deviceFields"]
  static values = { selected: String }

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const isReserved = this.selectedValue === "reserved"
    this.reservedMessageTarget.style.display = isReserved ? "block" : "none"
    this.deviceFieldsTargets.forEach(field => {
      field.style.display = isReserved ? "none" : "block"
    })
  }

  selectOption(event) {
    this.selectedValue = event.target.value
    this.updateVisibility()
  }
}
