import { Controller } from "@hotwired/stimulus"
import flash from "../lib/flashes.js";
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ["prompt", "chatHistory", "submitButton", "presetButton", "aiPromptId", "clearButton"]

  connect() {
    this.loadChatHistory()
    this.renderChatHistory()
    // Add event listener for page navigation
    window.addEventListener('beforeunload', this.clearChatHistory.bind(this))
  }

  disconnect() {
    // Remove event listener when controller disconnects
    window.removeEventListener('beforeunload', this.clearChatHistory.bind(this))
    this.clearChatHistory()
  }

  clearChatHistory() {
    localStorage.removeItem('aiChatHistory')
    this.loadChatHistory() // Reset to empty array
    this.renderChatHistory() // Clear the display
  }

  async submit(event) {
    event.preventDefault()
    const prompt = this.promptTarget.value.trim()
    if (!prompt) return

    this.submitButtonTarget.disabled = true

    // Add user message to history
    this.addToHistory('user', prompt)

    // Add assistant message with loading state
    const assistantMessage = { role: 'assistant', content: '<div class="loading-dots">...</div>', timestamp: new Date().toISOString() }
    const history = this.getChatHistory()
    history.push(assistantMessage)
    localStorage.setItem('aiChatHistory', JSON.stringify(history))
    this.renderChatHistory()
    this.promptTarget.value = ''

    try {
      const response = await fetch('/hudini/responses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          prompt: prompt,
          chat_history: this.getChatHistory(),
          record_type: this.element.dataset.recordType,
          record_id: this.element.dataset.recordId,
          ai_prompt_id: this.hasAiPromptIdTarget ? this.aiPromptIdTarget.value : null
        })
      })

      const data = await response.json()

      if (response.ok) {
        // Update the content of the last message in localStorage
        const updatedHistory = this.getChatHistory()
        updatedHistory[updatedHistory.length - 1].content = data.content
        localStorage.setItem('aiChatHistory', JSON.stringify(updatedHistory))
        this.renderChatHistory()
      } else {
        throw new Error(data.error || 'Something went wrong')
      }
    } catch (error) {
      // Remove the loading message from history
      const history = this.getChatHistory()
      history.pop() // Remove the assistant's loading message
      localStorage.setItem('aiChatHistory', JSON.stringify(history))

      console.error(error)
      // Show flash message
      flash(error.message || "Request failed", "error")

      // Re-render chat history without the error message
      this.renderChatHistory()
    } finally {
      this.submitButtonTarget.disabled = false
    }
  }

  getChatHistory() {
    return JSON.parse(localStorage.getItem('aiChatHistory') || '[]')
  }

  loadChatHistory() {
    if (!localStorage.getItem('aiChatHistory')) {
      localStorage.setItem('aiChatHistory', '[]')
    }
  }

  addToHistory(role, content) {
    const history = this.getChatHistory()
    history.push({ role, content, timestamp: new Date().toISOString() })
    localStorage.setItem('aiChatHistory', JSON.stringify(history))
  }

  renderChatHistory() {
    const history = this.getChatHistory()

    this.chatHistoryTarget.innerHTML = history.map(msg => `
      <div class="openai-message openai-message--${msg.role}">
        <h5 class="openai-message__header" style='${msg.role === 'user' ? 'display: none' : ''}'>
          Hudini
        </h5>
        <div class="openai-message__content">
          ${msg.content}
        </div>
        ${msg.role === 'assistant' ? `
          <div class="openai-message__actions">
            <div class="button-group">
              <a href="#" data-tippy-content="Copy Text" class="button button--icon--small button--icon" data-action="click->openai#copyRawMessage">
                <i class="far fa-copy"></i>
              </a>
              <a href="#" data-tippy-content="Copy HTML" class="button button--icon--small button--icon" data-action="click->openai#copyHtmlMessage">
                <i class="far fa-code"></i>
              </a>
            </div>
          </div>
        ` : ''}
      </div>
    `).join('')

    // Initialize tippy for newly added elements
    tippy('[data-tippy-content]', {
      appendTo: () => this.element
    })

    // Scroll to bottom
    this.chatHistoryTarget.scrollTop = this.chatHistoryTarget.scrollHeight
  }

  handleEnter(event) {
    // Submit on Enter, but allow Shift+Enter for new lines
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.submit(event);
    }
  }

  submitPreset(event) {
    event.preventDefault()
    const preset = event.currentTarget.dataset.preset
    this.promptTarget.value = preset
    this.submit(event)
  }

  async copyRawMessage(event) {
    event.preventDefault()
    const messageEl = event.target.closest('.openai-message')
    const contentEl = messageEl.querySelector('.openai-message__content')

    try {
        await navigator.clipboard.writeText(contentEl.textContent)
        this.showCopiedConfirmation(event.target)
    } catch (err) {
        console.error('Failed to copy text: ', err)
    }
  }

  async copyHtmlMessage(event) {
    event.preventDefault()
    const messageEl = event.target.closest('.openai-message')
    const contentEl = messageEl.querySelector('.openai-message__content')

    try {
        await navigator.clipboard.writeText(contentEl.innerHTML)
        this.showCopiedConfirmation(event.target)
    } catch (err) {
        console.error('Failed to copy text: ', err)
    }
  }

  showCopiedConfirmation(target) {
    const button = target.closest('a')
    const originalText = button.innerHTML
    button.innerHTML = '<i class="far fa-check"></i>'
    setTimeout(() => {
        button.innerHTML = originalText
    }, 2000)
  }

  clear(event) {
    event.preventDefault()
    this.clearChatHistory()
  }
}
