import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filterBar"]
  static values = {
    lastQuery: String
  }

  connect() {
    document.addEventListener('input', (event) => {
      if (event.target.matches('input[name="query"]')) {
        this.lastQueryValue = event.target.value
      }
    })
  }

  removeFilter(event) {
    event.preventDefault()

    const url = new URL(window.location)
    const searchParams = new URLSearchParams(url.search)

    const key = event.currentTarget.dataset.filterKey
    const value = event.currentTarget.dataset.filterValue

    // Special case for company_ids which is represented as 'c' in the URL
    const urlKey = key === "company_ids" ? "c" : key

    if (this.lastQueryValue?.trim()) {
      searchParams.set('query', this.lastQueryValue)
    } else {
      searchParams.delete('query')
    }

    const filters = searchParams.getAll(`filters[${urlKey}][]`)
    const newFilters = filters.filter(v => v !== value)

    searchParams.delete(`filters[${urlKey}][]`)
    newFilters.forEach(v => searchParams.append(`filters[${urlKey}][]`, v))

    url.search = searchParams.toString()
    Turbo.visit(url.toString(), { action: "advance" })
  }

  submitFilter(event) {
    event.preventDefault()

    const formData = new FormData(event.target)

    if (this.lastQueryValue?.trim()) {
      formData.append('query', this.lastQueryValue)
    }

    const searchParams = new URLSearchParams(formData)
    const url = new URL(window.location)
    url.search = searchParams.toString()

    Turbo.visit(url.toString(), { action: "advance" })
  }

  open(event) {
    event.preventDefault()
    this.filterBarTarget.classList.add("table-filter-bar--active")
  }

  close(event) {
    event.preventDefault()
    this.filterBarTarget.classList.remove("table-filter-bar--active")
  }
}
