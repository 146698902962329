import { Controller } from '@hotwired/stimulus';
import { useTransition } from 'stimulus-use';
import { computePosition, flip, shift, offset } from '@floating-ui/dom';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    useTransition(this, {
      element: this.menuTarget,
      enterActive: 'enter-active',
    });
  }

  toggle({ target: button }) {
    this.toggleTransition();
    this.updateMenuPosition(button);
  }

  async updateMenuPosition(button) {
    if (!this.menuTarget.classList.contains('enter-active')) return;

    const middleware = [
      offset(10),
      flip(),
      shift()
    ];

    const { x, y } = await computePosition(button, this.menuTarget, {
      placement: 'bottom',
      middleware,
      strategy: 'fixed'
    });

    Object.assign(this.menuTarget.style, {
      left: `${x}px`,
      top: `${y}px`
    });
  }

  hide(event) {
    const node = event.target;
    if (!this.element.contains(node) && !this.menuTarget.classList.contains('hidden')) {
      this.leave();
    }
  }
}
