<template>
  <div>
    <div class='drafter__major-error'>
      <div class='drafter__major-error__box' v-if='majorError'>
        <p><i class='fal fa-exclamation-triangle'></i> An error occurred saving this draft. Please copy the following HTML to an <b>external editor</b> to prevent losing unpublished changes.</p>
        <textarea :value='html_content' style='height: 150px' readonly></textarea>
        <a href='#' class='button--primary button' @click.prevent.stop='save'><i class='fas fa-redo'></i>Re-attempt Save</a>
      </div>
    </div>
    <div class='drafter'>
      <main class='drafter__content'>
        <div class='form-group'>
          <label for='articleTitle' class='required'>Name</label>
          <input v-model='title' @input='isDirty = true' required='true' type='text' id='articleTitle' placeholder='How to...' class='drafter__title'>
        </div>
        <div class='writer-wrap writer--article'>
          <input type='hidden' :value='title' name='article[name]'>
          <div class='drafter__writer'>
            <div class='form-group'>
              <textarea name='article[content]' id="mytextarea">{{precontent}}</textarea>
            </div>
          </div>
        </div>
      </main>
      <div class='drafter__finish'>
        <div class='drafter__autosave'>
          <h3 v-if='last_saved'><b>Last autosaved:</b> <span class='autosaved-date'>{{last_saved}}</span></h3>
          <h3 v-if='!last_saved'><b>Last autosaved:</b> <span class='autosaved-date'>{{last_updated}}</span></h3>
          <h3 v-if='restored && has_draft'>Restored from draft</h3>
        </div>
        <header v-if='restored && has_draft' class='drafter__finish__header drafter__finish__header--bordered'>
          <a href='#' @click.prevent.stop='unrestore' class='button button--secondary'>Revert to Published Version</a>
        </header>
        <header class='drafter__finish__header'>
          <a @click.prevent.stop='publish' class='button button--primary' href='#'>Publish</a>
          <a @click.prevent.stop='save' class='button button--secondary' href='#' v-if='isDirty'>Save Draft</a>
          <a class='button button--secondary' :href='close_url'>Close</a>
        </header>
        <main>
          <div class='asset-sidebar__group'>
            <header><h2>Meta</h2></header>
            <div class='asset-sidebar__group-form'>
              <folder-chooser v-on:changeFolder="folderWasChanged" name='article[folder_id]'
          :existing_name="folder_existing_name"
          :value='folder_value'
          :folders='folders'></folder-chooser>
            </div>
          </div>
        </main>
      </div>
    </div>

    <Teleport to="body">
      <div class='manage_modal__wrapper' v-show='isShown && active_user_name'>
        <div class='manage_modal manage_modal--confirm'>
          <header>
            <h1>{{active_user_name}} is currently editing</h1>
            <a :href='close_url' class='close'>
              <i class='fas fa-times'></i>
            </a>
          </header>
          <div class="manage__modal__body">
            <p>You may take over editing, but any unsaved changes made by the current editor will be lost.</p>
            <div class='button-group' style='margin-left: auto;'>
              <a class='button button--secondary' :href='close_url'>Cancel</a>
              <a :href='takeover_url' class='button button--danger'>Take Over Editing</a>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>

</template>

<script>
import FolderChooser from './FolderChooser.vue';
import Teleport from 'vue2-teleport';

/* Import TinyMCE */
import tinymce from 'tinymce';
import initTiny from "../lib/tinymce-editor.js";
import flash from '../lib/flashes.js';
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";
export default {

  props: ["precontent", "prename", "name", "value",
   "simple", "id", "autosave_url", "has_draft",
   "draft_title", "draft_content",  "last_saved", "last_updated",
   "article_url", "close_url", "takeover_url", "folder_existing_name", "draft_folder_existing_name", "draft_folder_value",
   "folders", "folder_value", "dark_mode", "active_user_name"],
  components: {
    FolderChooser,
    Teleport
  },
  mounted () {
    if (this.title) {
      document.title = this.title.length > 0 ? this.title : "Start writing..."
    }
    var that = this;

    tinymce.remove("#mytextarea");
    initTiny(that, "#mytextarea", 600, true)

    if (that.has_draft) {
      that.restore()
      that.restored = true
    }

    this.autosaveTimer = setInterval(() => {
      if (this.isDirty) {
        this.save();
      }
    }, this.autosave_seconds);
  },
  watch: {
    title: function (val) {
      document.title = val.length > 0 ? val : "Start writing..."
      this.allowSave = true
    },
    content: function (val) {
      this.allowSave = true
    }
  },
  methods: {
    copy () {
      var that = this;

      ClipboardFunctions.copyText(that.first_choice);
      that.isCopied = true;

      setTimeout(function(){ that.isCopied = false; }, 2000);

      flash("Copied to clipboard!", 'success')
    },
    getSuggestion() {
      var that = this;
      that.loading = true;
      that.$api.get(`${that.chatgpt_url}?prompt=${that.prompt}`).then(function (response) {
        that.first_choice = response.data.content;
        that.loading = false;
      }).catch(error => {
        alert(error.response.data.error)
      })
    },
    publish () {
      this.isDirty = false
      document.querySelector("#draftForm").submit()
    },
    restore () {
      var that = this;
      this.restored = true;
      this.title = this.draft_title;
      tinymce.activeEditor.on('init', function(e){
        e.target.setContent(that.draft_content);
      });
      this.isDirty = false
    },
    folderWasChanged () {
      this.isDirty = true;
    },
    unrestore () {
      var that = this;

      if(confirm('Are you sure?')) {
        this.restored = false;
        this.title = this.prename;
        tinymce.activeEditor.setContent(that.precontent)
        this.isDirty = false
        this.useExistingFolder = true
        this.folderValue = this.folder_value;
        this.folderName = this.folder_existing_name;
      }
    },
    save () {
      var that = this;
      var content = tinymce.activeEditor.getContent()

      that.$api.put(that.autosave_url, {
        "title" : that.title,
        "content": content,
        "folder_id": document.querySelector("#folderIdInput").value
      }).then(function (response) {
        that.isDirty = false;
        that.html_content = "";
        that.majorError = false;
        var date = response.data.date;
        document.querySelector(".autosaved-date").innerText = date;
        flash("Draft saved!", 'success')
      }).catch(error => {
        that.majorError = true
        that.html_content = content;

        flash("Something went wrong!", 'error')
      })
    }
  },
  data() {
    return {
      majorError: false,
      allowSave: false,
      allowPublish: false,
      prompt: "",
      loading: false,
      isCopied: false,
      numberOfChoices: 1,
      isShown: true,
      first_choice: "",
      editor: null,
      isDirty: false,
      isRichText: true,
      hasChanges: false,
      templates: [],
      restored: false,
      title: this.prename,
      content: this.precontent,
      autosave_seconds: 120000, // Every 2 minutes
      autosaveTimer: null
    }
  },
  beforeDestroy() {
    if (this.autosaveTimer) {
      clearInterval(this.autosaveTimer);
    }
  }
}
</script>
