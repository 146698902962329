import { Controller } from '@hotwired/stimulus';
import consumer from "../channels/consumer";

export default class extends Controller {
    static values = { isAgent: Boolean, recordId: Number }
    static targets = ["loader", "title", "progress", "processing", "completed"];

    connect() {
        const isAgent = this.isAgentValue;
        const recordId = this.recordIdValue;

        this.setStatusElement();
        this.updateAnimation();
        this.hideLoader();
        this.channel = consumer.subscriptions.create(
            {channel: "InstallerBuilderJobStatusChannel", is_agent: isAgent, record_id: recordId},
            {
                received: this.handleReceived.bind(this),
                connected: () => { console.log("Connected to channel"); },
                disconnected: () => { console.log("Disconnected from channel"); }
            }
        );
    }

    handleReceived(data) {
        let jobStatus = data["status"]
        let fileType = data["file_type"]

        let isCompleted = jobStatus === "completed";
       
        this.setStatusElement(isCompleted);

        this.updateAnimation(isCompleted);

        this.loaderTarget.style.display = "block";

        if(isCompleted === true) {
        // Hide element on click of link
        let link = this.completedTarget.querySelector('a');
        if(link) {
          const url = new URL(link.href);
          const params = url.searchParams;
          params.set('file_type', fileType); // Create or update
          url.search = params.toString();

          link.href = url.toString();
          link.addEventListener('click', (event) => {
            this.hideLoader();
          });
        }
      }

      if(jobStatus === "error") {
        this.hideLoader();
      }
    }

    setStatusElement(isCompleted) {
        this.titleTarget.textContent = isCompleted ? "Installer ready!" : "Building the installer";
        this.processingTarget.style.display = isCompleted ? "none" : "block";
        this.completedTarget.style.display = isCompleted ? "block" : "none";
    }

    updateAnimation(isCompleted) {
        if(isCompleted === true) {
            // Completed => stop animation
            this.progressTarget.style.width = "100%";
            this.progressTarget.style.animation = "none";
        } else {
            // Start progress indicator animation
            this.progressTarget.style.width = "20%";
            const keyframes = `
            @keyframes slide {
                0% {
                left: -20%; /* Start off-screen to the left */
                }
                50% {
                left: 100%; /* Slide off-screen to the right */
                }
                100% {
                left: -20%; /* Return to the starting position */
                }
            }
            `;
    
            // Add the keyframes to a style element
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = keyframes;
            document.head.appendChild(style);
            
            // Set animation
            this.progressTarget.style.animation = "slide 2s infinite";
        }
    }

    hideLoader() {
        this.loaderTarget.style.display = "none"
    }

    disconnect() {
        if(this.channel) {
            this.channel.unsubscribe();
        }
    }
}