import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["container"]
  static values = {
    fetchUrl: String,
    updateUrl: String
  }

  connect() {
    this.loadFields()
    this.initializeSortable()
  }

  async loadFields() {
    const response = await fetch(this.fetchUrlValue)
    const fields = await response.json()
    this.renderFields(fields)
  }

  initializeSortable() {
    this.sortable = new Sortable(this.containerTarget, {
      animation: 150,
      onEnd: this.handleSortEnd.bind(this)
    })
  }

  renderFields(fields) {
    this.containerTarget.innerHTML = fields.map((field, index) => `
      <div class="dashboard_item_sorter__item ${field.hidden ? 'dashboard_item_sorter__item--muted' : ''}"
           data-id="${field.id}">
        <article>
          <h1>
            <i class='fas fa-bars icon'></i>
            <a data-turbo-frame='_top' href='${field.url}'>${field.name}</a>
          </h1>
        </article>
      </div>
    `).join('')
  }

  async handleSortEnd(event) {

    const items = Array.from(this.containerTarget.children)
    const fields = items.map((item, index) => ({
      id: item.dataset.id,
      position: index + 1
    }))


    try {
      const response = await post(this.updateUrlValue, {
        body: JSON.stringify({ fields: fields }),
        responseKind: "json"
      })

      if (response.ok) {
        this.loadFields()
      } else {
        alert("Something went wrong...")
      }
    } catch (error) {
      console.log(error)
      alert("Something went wrong...")
    }
  }
}
