import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["updatesTab", "viewsTab", "updatesContent", "viewsContent"]

  showUpdates(event) {
    event.preventDefault()
    this.updatesTabTarget.classList.add("selected")
    this.viewsTabTarget.classList.remove("selected")
    this.updatesContentTarget.style.display = "block"
    this.viewsContentTarget.style.display = "none"
  }

  showViews(event) {
    event.preventDefault()
    this.viewsTabTarget.classList.add("selected")
    this.updatesTabTarget.classList.remove("selected")
    this.viewsContentTarget.style.display = "block"
    this.updatesContentTarget.style.display = "none"
  }
}
